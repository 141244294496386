/* eslint-disable consistent-return */
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import { showError } from '~/utils'
import { message } from 'ant-design-vue'
import Endpoint from '~/repository/endpoint'
import stringify from 'qs/lib/stringify'

export default {
  async updateUser(_ctx, { url, profile }) {
    try {
      const {
        data: { data: user },
      } = await this.$axios.patch(url, profile)
      message.success('Update user profile successfully!')
      this.$auth.setUser(user)
    } catch (error) {
      showError(error, 'Update user profile failed!')
      throw error
    }
  },
  async updateUserProfile(_ctx, { url, profile }) {
    try {
      const {
        data: { data: user },
      } = await this.$axios.patch(url, { profile })
      message.success('Update user profile successfully!')
      this.$auth.setUser(user)
    } catch (error) {
      showError(error, 'Update user profile failed!')
      throw error
    }
  },
  async getUserGameSpecs({ commit }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${userID}/specializations`)
      commit('setUserGameSpecs', data)
      return data
    } catch (error) {
      showError(error, 'Get user game specs failed!')
    }
  },
  async getUserGame({ commit, dispatch }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${userID}/games`)

      const specs = await dispatch('getUserGameSpecs', userID)
      const tempArr = Object.entries(groupBy(specs, 'userGameID')).reduce((prev, cur) => [...prev, { userGameID: cur[0], specs: cur[1] }], [])
      const responses = await Promise.all(
        data.map(i => this.$axios.get(`settings/game-specs?gameID=${i.gameID}`)),
      )
      const temp = responses.map(res => res.data.data)
      const userGames = data.map((ug, index) => {
        const obj = tempArr.find(i => i.userGameID === ug.id)
        if (obj) {
          // map spec name by key
          obj.specs = obj.specs.map(spec => {
            const tmpSpec = temp[index].find(x => x.key === spec.key)
            return {
              ...spec,
              name: tmpSpec ? tmpSpec.name : '',
            }
          })
          return { ...ug, specs: obj.specs }
        }

        return {
          ...ug,
          specs: [],
        }
      })

      commit('setUserGames', userGames)
      return userGames
    } catch (error) {
      showError(error, 'Get user games failed!')
    }
  },
  async getUserById({ commit }, id) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${id}/combine-info`)
      commit('setUser', data)
      return data
    } catch (error) {
      showError(error, 'Get user failed!')
    }
  },
  async updateUserGame(_ctx, userGames) {
    try {
      const games = userGames.map(item => {
        const {
          gameID, skillLevel, summary, yearsPlayed,
        } = item
        return {
          gameID,
          skillLevel,
          summary,
          yearsPlayed,
        }
      })

      await this.$axios.put(`/users/${this.$auth.user.id}/games`, {
        games,
      })
      this.$router.push('/languages')

      message.success('Update user game successfully!')
    } catch (error) {
      showError(error, 'Update user game failed!')
    }
  },
  async getUserByIdentifier({ commit }, identifier) {
    try {
      let userData = null
      if (identifier.type === 'nickname') {
        const {
          data: { data },
        } = await this.$axios.get(encodeURI(`/users/nickname/${identifier.value}`))
        userData = data
      } else {
        const {
          data: { data },
        } = await this.$axios.get(`/users/${identifier.value}`)
        userData = data
      }
      commit('setUserProfile', userData)
    } catch (error) {
      showError(error, 'Get user failed!')
    }
  },
  async getUserAchievements({ commit }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`/users/${userID}/achievements`)
      commit('setUserAchievements', data)
      return data
    } catch (error) {
      showError(error, 'Get user game achievements failed!')
    }
  },
  async getUserShowreel({ commit }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(`users/${userID}/media?type=video&includeUserGame=true`)
      if (data) {
        const res = await Promise.all(
          data
            .filter(item => get(item, 'userGame.gameID') != null)
            .map(item => this.$axios.get(`catalogs/games/${item.userGame.gameID}`)),
        )
        const games = res.map(item => get(item, 'data.data') || [])
        const showreels = data.map(item => {
          const game = games.find(g => g.id === get(item, 'userGame.gameID'))
          return {
            ...item,
            game,
          }
        })
        commit('setUserShowreels', showreels)
      }
    } catch (error) {
      showError(error, 'Get user medias failed!')
    }
  },
  async getUserFollowing({ commit, state }, userID) {
    try {
      if (state.userFollowing.length < 1) {
        const { data } = await this.$axios.$get(`users/follow-profile/following/${userID}`)

        if (data) {
          commit(
            'setUserFollowing',
            data.map(v => v.followingID),
          )
        }
      }
    } catch (error) {
      showError(error, 'Get user followings failed!')
    }
  },
  async followUser({ commit }, userId) {
    try {
      await this.$axios.$post('/users/follow-profile', {
        followingID: userId,
      })
      commit('ADD_TO_FOLLOWING', userId)
    } catch (error) {
      showError(error, 'Add user to followings failed!')
    }
  },
  async unfollowUser({ commit }, userId) {
    try {
      await this.$axios.$delete('/users/follow-profile/unfollow', {
        data: {
          followingID: userId,
        },
      })
      commit('REMOVE_FROM_FOLLOWING', userId)
    } catch (error) {
      showError(error, 'Remove user from followings failed!')
    }
  },
  async getIsUserFollowing({ commit }, creatorID) {
    try {
      const { data } = await this.$axios.$get(`users/follow-profile/is_following/${creatorID}`)
      if (data === true) {
        commit('ADD_TO_FOLLOWING', creatorID)
      }
      return data
    } catch (error) {
      showError(error, 'Get user followings failed!')
    }
  },
  async getUserSubscriptions({ commit }) {
    commit('setFetchSubscriptionLoading', true)
    try {
      const payload = {
        usecases: 'GANK_CREATOR_MEMBERSHIPS_ACTIVE_V2',
        perspective: 'FROM_BUYER',
      }
      const { data } = await this.$v2API.get(`/payment/user-info?${stringify(payload)}`)
      const result = data.current_creator_memberships_v2.data
      if (result.length) {
        commit(
          'setUserSubscriptions',
          result.map(v => v.membership_setting_id),
        )
        commit(
          'setUserSubscriptionsUserId',
          result.map(v => v.creator_user_id),
        )
        commit(
          'setUserSubscriptionsObject',
          result.map(v => v),
        )
      }
    } catch (error) {
      showError(error, 'Get user subscriptions failed!')
    } finally {
      commit('setFetchSubscriptionLoading', false)
    }
  },
  async getUserGoal({ commit }, userID) {
    try {
      commit('setFetchGoalLoading', true)
      let { data: goal } = await this.$axios.$get(`/wallets/goal-donation?isComplete=false&userID=${userID}`)
      if (goal?.length > 0) {
        goal = goal.find(item => !item.isComplete)
      } else {
        goal = null
      }
      commit('setUserGoal', goal)
      return goal
    } catch (error) {
      showError(error, 'Get user goal failed!')
    } finally {
      commit('setFetchGoalLoading', false)
    }
  },
  async getUserSummary({ commit }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('/catalogs/user-summary', {
        params: {
          userID,
        },
      })

      commit('setUserSummary', data)
    } catch (error) {
      showError(error)
    }
  },
  async getUserTier({ commit }, userID) {
    try {
      if (userID !== undefined) {
        const authToken = await this.$auth.strategy.token.get()
        const { data } = await this.$axios.get(`${Endpoint.userPaymentV2}?usecases=GANK_TIER_MEMBERSHIPS_ACTIVE_V2`,
          {
            baseURL: Endpoint.apiUrl,
            headers: authToken ? { Authorization: authToken } : {},
          })
        const userTierData = data?.current_gank_tiers_v2?.data[0] || {
          tier: 'FREE',
        }

        commit('setUserTier', userTierData)
      }
    } catch (error) {
      showError(error)
    }
  },
  async getUserCompletedGoals({ commit }, userID) {
    try {
      const {
        data: { data },
      } = await this.$axios.get(
        `wallets/goal-donation?isComplete=true&isActive=true&userID=${userID}`,
      )
      const result = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      commit('setUserCompletedGoals', result)
    } catch (error) {
      showError(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getUserOnboardingTask({ commit }) {
    try {
      const {
        data: { data },
      } = await this.$axios.get('users/creator/onboarding-state')
      const userProgressionTaskData = data || { signUpReason: [], taskList: {} }
      commit('setUserProgressionTask', userProgressionTaskData)
    } catch (error) {
      showError(error)
    }
  },
  async getUserDiscountPPV({ commit }, userID) {
    try {
      const { data } = await this.$apiV2.get(`/payment/users/vouchers/by-creator?creator_user_id=${userID}&usecases=DISCOUNT_ALL,DISCOUNT_PPV&is_discount_active=true`)
      commit('setUserDiscountPPV', data.data || [])
    } catch (error) {
      showError(error)
    }
  },
  async getUserDiscountShop({ commit }, userID) {
    try {
      const { data } = await this.$apiV2.get(`/payment/users/vouchers/by-creator?creator_user_id=${userID}&usecases=DISCOUNT_ALL,DISCOUNT_CATALOG&is_discount_active=true`)
      commit('setUserDiscountShop', data.data || [])
    } catch (error) {
      showError(error)
    }
  },
  async getUserDiscountMembership({ commit }, userID) {
    try {
      const { data } = await this.$apiV2.get(`/payment/users/vouchers/by-creator?creator_user_id=${userID}&usecases=DISCOUNT_ALL,DISCOUNT_MEMBERSHIP&is_discount_active=true`)
      commit('setUserDiscountMembership', data.data || [])
    } catch (error) {
      showError(error)
    }
  },
  loadAllUserDiscount({ dispatch }, userID) {
    dispatch('getUserDiscountPPV', userID)
    dispatch('getUserDiscountShop', userID)
    dispatch('getUserDiscountMembership', userID)
  },
}
