/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
import {
  Line,
  Pie,
} from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  defaults,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  ArcElement,
)

// Access Chart.js utilities
const months = defaults.time?.units?.months || [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

Vue.prototype.$chartUtils = { months }

Vue.component('LineChart', {
  extends: Line,
})

Vue.component('PieChart', {
  extends: Pie,
})
