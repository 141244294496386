// Helper function to send logs to Loki
const sendToLoki = async (level, ...args) => {
  try {
    // Use current timestamp in nanoseconds
    const timestamp = Date.now() * 1000000 // Convert to nanoseconds

    // Extract component name and other metadata from args if they exist
    let component = 'unknown'
    let metadata = {}

    args.forEach(arg => {
      if (typeof arg === 'object' && arg !== null) {
        if (arg.component) {
          component = arg.component
        }
        metadata = { ...metadata, ...arg }
      }
    })

    const labels = {
      level,
      job: 'webchat_logging',
      environment: process.env.NUXT_ENV_TYPE || 'development',
      container: 'webchat-web',
      app: 'webchat-web',
      service: 'frontend',
      component,
      version: process.env.NUXT_ENV_VERSION || '2.45.1',
    }

    const message = args.map(arg => {
      if (arg instanceof Error) {
        return {
          message: arg.message,
          stack: arg.stack,
          name: arg.name,
          code: arg.code,
        }
      }
      if (typeof arg === 'object' && arg !== null) {
        // Handle error object in metadata
        if (arg.error instanceof Error) {
          return {
            ...arg,
            error: {
              message: arg.error.message,
              stack: arg.error.stack,
              name: arg.error.name,
              code: arg.error.code,
              response: arg.error.response ? {
                status: arg.error.response.status,
                statusText: arg.error.response.statusText,
                data: arg.error.response.data,
              } : undefined,
            },
          }
        }
        return JSON.stringify(arg)
      }
      return String(arg)
    })

    const payload = {
      streams: [
        {
          stream: labels,
          values: [[timestamp.toString(), JSON.stringify({
            message,
            metadata,
            timestamp: new Date().toISOString(),
            level,
            labels,
          })]],
        },
      ],
    }

    // Only send to Loki in production or if explicitly enabled
    const isProduction = process.env.NUXT_ENV_TYPE === 'production'
    const isLoggingEnabled = process.env.NUXT_ENV_ENABLE_LOGGING === 'true'

    if (isProduction || isLoggingEnabled) {
      const lokiHost = process.env.NUXT_ENV_LOKI_HOST || 'http://localhost:3100'
      const lokiAuth = process.env.NUXT_ENV_LOKI_AUTH

      // console.debug('Sending log to Loki:', {
      //   url: `${lokiHost}/loki/api/v1/push`,
      //   payload,
      //   env: {
      //     type: process.env.NUXT_ENV_TYPE,
      //     lokiHost: process.env.NUXT_ENV_LOKI_HOST,
      //     enableLogging: process.env.NUXT_ENV_ENABLE_LOGGING,
      //   },
      // })

      const headers = {
        'Content-Type': 'application/json',
      }

      // Add basic auth headers if auth string is provided
      if (lokiAuth) {
        const basicAuth = Buffer.from(lokiAuth).toString('base64')
        headers.Authorization = `Basic ${basicAuth}`
      }

      const response = await fetch(`${lokiHost}/loki/api/v1/push`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        const error = await response.text()
        console.error('Loki logging failed:', error, {
          status: response.status,
          statusText: response.statusText,
          url: lokiHost,
          payload: JSON.stringify(payload),
        })
      } else {
        console.debug('Log sent successfully to Loki')
      }
    }
  } catch (error) {
    console.error('Failed to send log to Loki:', error)
  }
}

// Client-side logger implementation
const createClientLogger = () => ({
  info: (...args) => {
    sendToLoki('info', ...args)
  },
  error: (...args) => {
    sendToLoki('error', ...args)
  },
  warn: (...args) => {
    sendToLoki('warn', ...args)
  },
  debug: (...args) => {
    sendToLoki('debug', ...args)
  },
})

// eslint-disable-next-line no-unused-vars
export default ({ app }, inject) => {
  const logger = createClientLogger()
  inject('logger', logger)
}
